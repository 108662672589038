/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'react-router'
import { Button } from '../button/button'
import { Icon } from '../icon/icon'
import { Input } from '../ui/input'
import { useOnOutsideClick } from '~/hooks/useOnOutsideClick'
import { type lngShort } from '~/utils/i18n.utils'
import type { AutocompletePlaceSearch } from '~/types/googleSearch'
import type { AutocompleteSearch } from '~/types/autoComplete'

// TODO: Add a better solution for this component to be able to search without
// triggering every remix route above (root, places, etc.)
export default function HeaderSearchPlaces({
	resolution = 'cities',
	language,
	setPlaceResult,
	locationQuery,
}: {
	resolution?: 'cities' | 'address'
	language: lngShort
	setPlaceResult: (result: any | null) => void
	locationQuery: string | undefined
}) {
	const autocompleteFetcher = useFetcher<AutocompleteSearch[]>()
	const placeFetcher = useFetcher<AutocompletePlaceSearch>()
	const { t } = useTranslation()
	const dropdownRef = useRef<HTMLDivElement | null>(null)
	useOnOutsideClick(dropdownRef, () => setShowDropdown(false))

	const [showDropdown, setShowDropdown] = useState<boolean>(false)
	const [autocompleteResults, setAutocompleteResults] = useState<
		AutocompleteSearch[]
	>([])
	const [inputValue, setInputValue] = useState<string>(locationQuery ?? '')
	useOnOutsideClick(dropdownRef, () => setShowDropdown(false))

	const getAutocomplete = (text: string) => {
		setInputValue(text)
		if (text.trim() !== '') {
			autocompleteFetcher.submit(
				{ text: text, resolution: resolution, language: language },
				{
					method: 'POST',
					action: '/api/location/autocomplete-search',
				},
			)
		} else {
			setAutocompleteResults([])
			setPlaceResult(null)
		}
	}

	const getPlace = (result: AutocompleteSearch) => {
		setShowDropdown(false)
		if (autocompleteFetcher.data?.length !== 0) {
			placeFetcher.submit(
				{ place_id: result.place_id, language: language },
				{
					method: 'POST',
					action: '/api/location/autocomplete-place',
				},
			)
		}
	}

	const clearInput = () => {
		setInputValue('')
		setAutocompleteResults([])
		setPlaceResult(null)
	}

	useEffect(() => {
		if (autocompleteFetcher.state === 'idle' && autocompleteFetcher.data) {
			if (autocompleteFetcher.data.length > 0) {
				setShowDropdown(true)
				setAutocompleteResults(autocompleteFetcher.data)
			}
		}
	}, [autocompleteFetcher])

	useEffect(() => {
		if (placeFetcher.state === 'idle' && placeFetcher.data) {
			setInputValue(placeFetcher.data.name)
			setPlaceResult(placeFetcher.data)
		}
	}, [placeFetcher])

	return (
		<div
			className="relative z-10 w-full bg-background"
			onFocus={() => setShowDropdown(true)}
			ref={dropdownRef}
		>
			<div className="relative col-span-12">
				<Input
					type="text"
					value={inputValue}
					autoFocus
					onChange={e => getAutocomplete(e.target.value)}
					placeholder={t('action_search_for_location')}
				/>
				{inputValue && (
					<div className="absolute right-[3.5px] top-[2.5px]">
						<Button
							onClick={clearInput}
							variant="primary"
							size="sm"
							className="!h-9 !w-9 animate-fade !rounded-full !p-2.5"
							tabIndex={-1}
							title={t('action_reset_location_search')}
							type="button"
						>
							<Icon name="cross" size="lg" />
						</Button>
					</div>
				)}
			</div>
			{showDropdown &&
				autocompleteResults &&
				autocompleteResults.length > 0 && (
					<div className="bg absolute z-10 mx-auto w-full animate-fade rounded-xl bg-background shadow-md first-letter:text-foreground">
						<ul className="divide-y">
							{autocompleteResults.map(result => (
								<li
									className="flex rounded-xl hover:cursor-pointer hover:bg-foreground/10"
									key={result.place_id}
								>
									<button
										className="flex w-full items-center p-4 focus:bg-foreground/10"
										onClick={() => getPlace(result)}
										type="button"
									>
										<div className="flex h-10 w-10 content-center">
											<Icon
												size="lg"
												name="position-marker"
												className="text-slate-300"
											/>
										</div>
										<div className="ml-3 flex content-center">
											<p className="flex items-center text-sm font-medium">
												{result.name}
											</p>
										</div>
									</button>
								</li>
							))}
						</ul>
					</div>
				)}
		</div>
	)
}
